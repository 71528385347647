import React from "react"
import '../styles/app.css'
import { Link } from "gatsby";
import Layout from "../components/layout"

export default ({ location }) => (

	<Layout 
    path={location.pathname}>

		<h1 className="text-xl">Contact!</h1>

		<div className="bg-black text-white">
			<Link to="/contact">Contact</Link>
		</div>

	</Layout>

)
